<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Standorte</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/locations/new" class="button button-orange button-small">Standort hinzufügen ›</router-link></p>
            </div>
          </header>

          <div class="admin-body">

            <div class="search-wrap">
              <input v-model="search_phrase" v-on:input="search_handler" type="text" class="search-field" placeholder="Suchbegriff eingeben...">
            </div>

            <div v-if="search_loading">
              <div class="loading-wrap">
                <div class="loading-spinner"></div>
              </div>
            </div>
            <div v-else>

              <table class="table">
                <thead>
                  <tr>
                    <th width="5%"></th>
                    <th width="10%"></th>
                    <th width="35%">Name</th>
                    <th width="30%" style="text-align:center">Fahrzeuge</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="location in locations" v-bind:key="location.id">
                    <td>
                      <span v-if="location.status == 'enabled'" class="status status-green"></span>
                      <span v-if="location.status == 'disabled'" class="status status-red"></span>
                    </td>
                    <td v-if="location.image">
                      <img :src="location.image" width="50" height="50">
                    </td>
                    <td v-else>
                      <div style="width:50px;height:50px;background:#f1f1f1"></div>
                    </td>
                    <td>
                      <router-link :to="'/admin/locations/'+location.id">
                        {{ location.name }}
                      </router-link>
                    </td>
                    <td style="text-align:center">
                      <router-link :to="'/admin/locations/'+location.id">
                        {{ location.vehicle_count }}
                      </router-link>
                    </td>
                    <td>
                      <router-link :to="'/admin/locations/'+location.id">
                        <span class="material-icons">create</span>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_locations',
  components: {
    AdminNav
  },
  data () {
    return {
      table: {
        columns: [
          {
            label: 'Name',
            field: 'name',
          },
          {
            label: 'Aktiv?',
            field: 'enabled',
          }
        ],
      },
      locations: [],
      search_phrase: "",
      search_loading: false
    }
  },
  methods: {
    get_locations() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.locations = response.data.locations;
      })
      .catch(error => {
        console.log(error);
      })

    },
    on_row_click(params) {
      console.log(params.row.id);
      this.$router.push('/admin/locations/'+params.row.id);
    },
    search_handler() {
      if (this.search_loading == false) {
        this.search_loading = true;
        axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations?search_phrase='+this.search_phrase, { headers: { Authorization: this.$store.getters.getToken } })
        .then(response => {
          this.locations = response.data.locations;
          this.search_loading = false;
        })
        .catch(error => {
          console.log(error);
        })
      }
    }
  },
  mounted () {
    this.get_locations();
  }
}
</script>

<style lang="scss">

</style>
